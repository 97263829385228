<template>
    <code-highlight language="html">
        <code class="language-html language-prism-html-markup">
            {{ code }}
        </code>
    </code-highlight>
</template>
<script>
import CodeHighlight from "vue-code-highlight/src/CodeHighlight.vue";
import 'prism-es6/components/prism-markup-templating';
//import '../../../node_modules/vue-code-highlight/themes/window.css';
import "vue-code-highlight/themes/duotone-sea.css";

export default 
{
    components: { CodeHighlight },
    props:
    {
        code:
        {
            required:true,
            type: String
        }
    }
}
</script>
<style>
.token.tag
{
    color:#1976d2!important;
    font-weight: 900!important;
}
.token .attr-value
{
    color:#2196f3!important;
    font-weight: 100!important;
}
</style>